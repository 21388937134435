<template>
<div>
   <!-- BOC:[header] -->
   <div class="d-flex pb-6 text-right">
          <v-spacer></v-spacer>
        <!-- <div class="pr-3">
          <SendCheckpoint
            :url="`${$api.servers.moderator}/v1/event/${parent.id}/checkpoint`"
          />
        </div> -->
        <!-- <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorThemeWorkshopDrawingManage',
            params: { parentId: parent.id, parent: parent},
          }"
        >
          + Send Award
        </v-btn> -->
      </div>
    <!-- EOC -->
  <!-- BOC:[table] -->
  <BreadBrowseTable
    role="Moderator"
    :model="model"
    :url="`${$api.servers.game}/api/v1/zh/moderator/gallery/${parent.year}/theme/${this.$route.params.id}/workshopDrawing`"
    :isSearchable="true"
    :parent="parent"
    :modelParent="modelParent"
  ></BreadBrowseTable>
  <!-- EOC -->
</div>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/theme'
  import model from '@/models/items/galleryWorkshopDrawing'
  //EOC
  //BOC:[table]
  import BreadBrowseTable from '@/components/Bread/BreadBrowseTableV2'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //BOC:[table]
      BreadBrowseTable,
      //EOC
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'role',
      'parent',
    ],
    data:()=>({
      //BOC:[model]
      modelParent:modelParent,
      model: model,
      //EOC
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>